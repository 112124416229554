<template>
	<div>
		<input type="file" @change="handleFileChange" />
		<canvas ref="canvas"></canvas>
	</div>
</template>
  
  <script>
import { fabric } from "fabric";

export default {
	data() {
		return {
			canvas: null,
			image: null,
		};
	},
	mounted() {
		this.canvas = new fabric.Canvas(this.$refs.canvas);
		this.addFrame();
		this.resize();
		window.addEventListener("resize", this.resize);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.resize);
	},
	methods: {
		handleFileChange(event) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				fabric.Image.fromURL(reader.result, (img) => {
					this.image = img;
					this.canvas.clear();
					this.canvas.add(this.image);
					this.addFrame();
				});
			};
		},
		addFrame() {
			const frameSrc = require("@/assets/frame.png");
			fabric.Image.fromURL(frameSrc, (img) => {
				img.scaleToWidth(this.canvas.width);
				img.scaleToHeight(this.canvas.height);
				this.canvas.setOverlayImage(img, null, null, null, null, true);
				this.canvas.renderAll();
			});
		},
		resize() {
			this.canvas.setWidth(window.innerWidth);
			this.canvas.setHeight(window.innerHeight);
			const frame = this.canvas.getObjects("overlayImage")[0];
			if (frame) {
				frame.scaleToWidth(this.canvas.width);
				frame.scaleToHeight(this.canvas.height);
			}
			this.image && this.image.scaleToWidth(this.canvas.width);
			this.image && this.image.scaleToHeight(this.canvas.height);
			this.canvas.renderAll();
		},
	},
};
</script>
  
  <style>
canvas {
	width: 100%;
	height: 100%;
}
</style>